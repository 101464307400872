import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {

  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - आरती" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='आरती'/>
        <div id="main">
          <section id="content" className="main">
            <p>
॥ आरती गुरू गजाननाची ॥< br />
< br />
जय जय सच्चिदानंद । स्वामी गजानना ।< br />
आरती ओवाळू तुज । तार तू मूढ जना ॥< br />
जयदेव जयदेव ॥ धृ ॥< br />
< br />
अचेतनासी चेतन करिशी । तव लीलेतूनी ।< br />
नित्य अनित्य बोध करिशी । तू तव भक्तांसी ॥< br />
जयदेव जयदेव ॥ १ ॥< br />
< br />
नवस नका हो विसरू । सांगशी भक्तांसी ।< br />
नवस पूर्ण होता । चमत्कृती दाविशी ॥< br />
जयदेव जयदेव ॥ २ ॥< br />
< br />
कुठचेहि संकट असुद्या । पाच वार्‍यांत निवारिसी ।< br />
शेगावचा महिमा तू । जगी अमर करिशी ॥< br />
जयदेव जयदेव ॥ ३ ॥< br />
< br />
वेदांचे सार सांगशी । तू तव भक्तांसी ।< br />
चमत्कृतीतुनी ज्ञानी । तू त्यांना करिशी ॥< br />
जयदेव जयदेव ॥ ४ ॥< br />
< br />
व्याधी मुक्त करिशी । नर-नारायण करिशी ।< br />
गणामहाराज प्रार्थे । रक्ष तू भक्तांसी ॥< br />
जयदेव जयदेव ॥ ५ ॥< br />

</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
