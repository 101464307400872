import React from 'react'
import { Link } from 'gatsby'

import homeIcon from '../assets/images/homeIcon.svg';

const HeaderGeneric = (props) => (
    <div>
        <Link to="/">
            <span className="homeIcon"><img src={homeIcon} alt="" style={{ 'width': '10%', 'marginTop': '2%' }} /></span>
        </Link>
        <header id="header">
            <h1>{props.title}</h1>
            <p>{props.adhyaay}</p>
            <p>
                { (props.num != null) &&
                    <span>
                        <p>श्री. हेमंत दादा करंदीकर यांच्या प्रासादिक आवाजात गुरू गजानन लीला ग्रंथाचे पारायण</p>
                        <audio controls><source src={`/hemant_mp3/Adhyay_${props.num}.mp3`} type="audio/mpeg" ></source></audio>
                        <p>श्री. श्रीहरी वा. करमरकर, नवी मुंबई यांच्या सुश्राव्य वाणीतून सादर केलेले सुश्राव्य ध्वनीमुद्रित पारायण</p>
                        <audio controls><source src={`/mp3/Guru_Gajanan_Leela_${props.num}.mp3`} type="audio/mpeg" ></source></audio>
                    </span>
                }
            </p>
        </header>
    </div>
)

export default HeaderGeneric
